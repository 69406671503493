import React, { FC } from 'react';
import { ProgressScope } from '../../interfaces/api.types';
import { Card, H3, SpanBody1, SpanBody2, SpanH6 } from '@linkeo.com/ui-lib-react';
import { TargetingForm } from './targeting-form';
import { ActivitiesSelection } from './activities-selection';
import { ContactForm } from './contact-form';
import { ActivityForm } from './activity-form';
import { FormFooter } from './form-footer';
import { PaddingForCard } from '../ui/padding-for-card';
import { useIntl } from 'react-intl';

interface FormPresentationProps {
  isLoading: boolean;
  noTargeting: boolean;
  onActivitiesSelected: (activities: ProgressScope[]) => void;
  onAnswer: (progress: ProgressScope) => void;
  onFinish: (progress: ProgressScope) => void;
  onNext: () => void;
  onPrevious: () => void;
  progress?: ProgressScope;
  isUniqueActivity: boolean;
  onIneligibleFormEnd: () => void;
}

export const FormPresentation: FC<FormPresentationProps> = ({
                                                              onAnswer,
                                                              progress,
                                                              onActivitiesSelected,
                                                              onPrevious,
                                                              onFinish,
                                                              isLoading,
                                                              onNext,
                                                              noTargeting,
                                                              isUniqueActivity,
                                                              onIneligibleFormEnd,
                                                            }) => {

  const intl = useIntl();

  return progress ? <Card style={{ padding: '0' }}>
    <PaddingForCard as={'header'} style={{ textAlign: 'center' }}>
      <h2 style={{ margin: '0' }}>
        <SpanH6 style={{ textTransform: 'uppercase' }}>
          {progress.title}
        </SpanH6>
      </h2>
    </PaddingForCard>
    <hr />
    {
      progress.scope === 'TARGETING' ?
        <TargetingForm
          context={progress}
          onAnswer={onAnswer} />
        : null
    }
    {
      progress.scope === 'ACTIVITY' && progress.isActivityPresentation ? <>
        <PaddingForCard style={{ textAlign: 'center' }}>
          <H3>{intl.formatMessage({ id: 'newStepTitle', defaultMessage: 'Nouvelle étape' })}</H3><br />
          <SpanBody1>{intl.formatMessage({
            id: 'newStepSubtitle',
            defaultMessage: 'Nous allons passer aux questions à propos de :',
          })}<br /> </SpanBody1>
          <SpanBody2>{progress.title}</SpanBody2>
          <br />
          {progress.activityImage ?
            <div
              style={{
                width: '100%',
                maxWidth: '230px',
                height: '160px',
                position: 'relative',
                margin: '32px auto 0',
              }}>
              <img width={'100%'} height={'100%'} style={{ objectFit: 'cover' }}
                   src={progress.activityImage} alt={'activity img'} />
            </div> : null}
        </PaddingForCard>
        <FormFooter
          onClick={onNext}
          title={intl.formatMessage({ id: 'next', defaultMessage: 'Suivant' })}
          onCancel={isUniqueActivity && noTargeting ? undefined : onPrevious} />
      </> : null
    }
    {
      progress.scope === 'ACTIVITY' && progress.categories ?
        <ActivitiesSelection
          noTargeting={noTargeting}
          onPrevious={onPrevious}
          context={progress}
          onActivitiesSelected={onActivitiesSelected} />
        : null
    }
    {
      (progress.scope === 'ACTIVITY' || progress.scope === 'ACTIVITY_QUESTIONS') && progress.activityGroup ?
        <ActivityForm
          onPrevious={onPrevious}
          context={progress}
          onIneligibleChoiceSelection={onIneligibleFormEnd}
          onAnswer={onAnswer} />
        : null
    }
    {
      progress.scope === 'CONTACT' && progress.groups ?
        <ContactForm
          context={progress}
          onAnswer={onFinish}
          isLoading={isLoading}
          onPrevious={onPrevious} />
        : null
    }

  </Card> : null;
};
